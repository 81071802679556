import { render, staticRenderFns } from "./PayCoupon.vue?vue&type=template&id=5ad64607&scoped=true"
import script from "./PayCoupon.vue?vue&type=script&lang=js"
export * from "./PayCoupon.vue?vue&type=script&lang=js"
import style0 from "./PayCoupon.vue?vue&type=style&index=0&id=5ad64607&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad64607",
  null
  
)

export default component.exports