<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="购买优惠券"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="payBox">
        <div class="main">
          <div class="payItem">
            <div class="rowText rowText-first">
              <span class="leftTit">劵类型</span>
              <!-- <van-popup
                  v-model="showTypePopup"
                  @close="onTypeClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="couponTypeColumns"
                    @change="onTypeChange"
                    @confirm="onTypeConfirm"
                    @cancel="onTypeCancel"
                    show-toolbar
                    title="选择优惠券类型"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup> -->
                <div class="tit_top" @click="openCouponTypeSelect">
                  <span class="coupon_name">{{couponSelTextVal}}</span>
                   <van-icon v-if="!showCouponType" class="typeIcon" name="arrow-down"  />
                   <van-icon v-if="showCouponType" class="typeIcon" name="arrow-up" />

                </div>

            </div>
            <van-divider />
            <div class="rowText">
              <div class="ruleBox">
                <span class="leftTit">充值额度</span>
                <span class="rule" v-if="!jeFlag">收费规则：{{ payRule }}</span>
                <!-- <span class="rule" wx:if="{{index==2}}">收费规则：{{couponRule[2].rule}}（元）/张</span>
          <span class="rule" wx:if="{{index==3}}">收费规则：{{couponRule[3].rule}}（元）/张</span> -->
              </div>

              <!-- <div>122张</div> -->
              <!-- <van-cell-group
                style="
                  width: 70%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              > -->
              <van-field
                v-model="numValue"
                clearable
                clickable
                type="Number"
                input-align="right"
                placeholder="输入充值额度"
                border
                @change="onNumChange"
                @input="onNumInput"
              />
              <span v-if="selName == '时长减免券'">分</span>
              <span v-if="selName == '金额减免券'">元</span>
              <span v-if="selName == '折扣减免券' || selName == '全免券'"
                >张</span
              >
              <!-- </van-cell-group> -->
              <!-- <input class="weui-input" type="number" placeholder="这是一个数字输入框" /> -->
            </div>
          </div>
          <div class="payItem">
            <div class="rowText rowText-first">
              <span class="leftTit">商家折扣</span>
              <span class="discount">{{
                discount == 0 ? '无折扣' : discount + '%'
              }}</span>
              <!-- <span class="discount" wx:if="{{discount==0}}">无折扣</span> -->
            </div>
            <van-divider />
            <div class="rowText">
              <!-- <div> -->
              <span class="leftTit" style="line-height: 44rpx">应收金额</span>
              <!-- </div> -->
              <!-- <div>122元</div> -->
              <!-- <van-cell-group
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <van-field
                  v-model="moneyValue"
                  disabled
                  type="Number"
                  input-align="right"
                  :border="false"
                  @change="onNumChange"
                /> -->
              <span>{{ moneyValue }}元</span>
              <!-- </van-cell-group> -->
            </div>
          </div>
          <div class="payItem backComment">
            <span class="commentTit leftTit">备注</span>
            <div class="rowText rowText_backComment">
              <van-field
                v-model="textareaVal"
                rows="1"
                autosize
                label=""
                type="textarea"
                placeholder="请输入备注"
                @blur="textareaBlur"
                @input="textareaInput"
              />
            </div>
          </div>
        </div>
        <div class="text-wrapper_4">
          <van-button
            style="font-family: PingFangSC-Regular, sans-serif"
            color="#4797EA"
            size="large"
            @click="confirmPay"
            >确定付款</van-button
          >
        </div>
      </div>

      <!-- 跳转本插件需要传递的参数 pay_id 支付订单编号 -->
      <!-- <navigator url="plugin://myPayPP/Create?pay_id=123">
    <button bindtap="confirmPay" class="text-wrapper_4">
    <span lines="1" class="text_7">确定付款</span>
  </button>
</navigator> -->

      <!-- <button bindtap="confirmPay" class="text-wrapper_4">
    <span lines="1" class="text_7">确定付款</span>
  </button> -->

    </div>
    <!-- 优惠券类型 -->
     <coupon-type-select
                ref="couponTypeSelect"
                :show-coupon-type="showCouponType"
                @getConfirm="couponTypeConfirm"
                @getCancel="couponTypeCancel"
              ></coupon-type-select>
  </div>
</template>
<script>
import CouponTypeSelect from '../../../components/CouponTypeSelect.vue'
import * as util from '../../../api/util'
export default {
  components: { CouponTypeSelect },
  data () {
    return {
      tokenInfoObj: {},
      array: ['金额减免券', '时长减免券', '折扣减免券', '全免券'],
      couponRule: [{
        id: 1,
        rule: '',
        name: '金额减免券',
        type: 1
      },
      {
        id: 2,
        rule: '12',
        ruleName: '（元）/分',
        name: '时长减免券',
        type: 2
      },
      {
        id: 3,
        rule: '12',
        ruleName: '（元）/张',
        name: '折扣减免券',
        type: 3
      },
      {
        id: 4,
        rule: '1',
        ruleName: '（元）/张',
        name: '全免券',
        type: 4
      }

      ],
      nowPayRule: [],
      index: 0,
      payRule: '',
      numValue: '',
      moneyValue: 0, // 总费用（分）
      shouldPayVal: 0, // 应实际支付费用（分）
      user_info: {},
      token: '',
      sid: '',
      customer_id: '',
      rule_id: '',
      discount: 0,
      textareaVal: '',
      rechargeLimit: '',
      selName: '',
      jeFlag: null,
      userProfile: {},
      showCouponType: false,
      couponSelTextVal: '',
      couponSelVal: ''

    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    openCouponTypeSelect () {
      this.showCouponType = true
    },
    confirmPay () {

    },
    // ToDo
    couponTypeConfirm (data) {
      this.showCouponType = false
      const dataVal = util.getCouponValByname(data)
      console.log('选中的优惠券类型名称', data)
      console.log('选中的优惠券类型value', dataVal)
      this.couponSelTextVal = data
      this.couponSelVal = dataVal
      let payRule = ''

      const selName = this.array[this.couponSelVal]
      let jeFlag = null
      this.couponRule.forEach((item) => {
        if (selName === item.name) {
          payRule = item.rule / 100 + item.ruleName
        }
        if (selName === '金额减免券') {
          jeFlag = true
        } else {
          jeFlag = false
        }
        console.log('payRule', payRule)
      })

      // selName: selName,
      // index: e.detail.value,
      this.payRule = payRule
      this.jeFlag = jeFlag
    },
    couponTypeCancel () {
      this.showCouponType = false
    },
    onNumChange () {

    },
    onNumInput () {

    },
    textareaBlur () {

    },
    textareaInput () {

    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.payBox {
  width: 3.43rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 32rpx; */
  margin: 0.16rem auto;
  .payItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 0.16rem;
    padding-top: 0.16rem;
    padding-bottom: 0.16rem;
    border-radius: 0.04rem;
    .commentTit {
      text-align: left;
      padding: 0 0.1rem;
    }
    .rowText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.1rem;
      font-size: 0.16rem;
      color: #000;
      .typeIcon {
        font-size: 0.14rem;
      }
      .ruleBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 40%;

        .rule {
          color: #999;
          font-size: 0.12rem;
          margin-top: 0.04rem;
        }
      }
      .discount {
        font-size: 0.14rem;
        color: #999;
      }
    }
    .leftTit {
      font-size: 0.16rem;
    }
  }
  .rowText-first {
    padding: 0.11rem;
  }
}
</style>
