import axios from 'axios'
import * as commondef from './common'
import $store from '../store/index'
let accessToken = null

export const getPublicPara = function (notneed) {
  const params = {
    app_key: '3F6AE6D9E-244D-46B1-A8CC-3D5A90C3823A',
    app_pass: 'D410FAC1-7b0A-4522-AFEA-B3650AF14CA1'
  }
  if (!notneed) {
    params.access_token = $store.state.accessToken.accessToken
    // params.access_token = 'CAAED5F4-86D0-0001-694D-34AA15008E90'
  }
  return params
}

export const axioInstance = axios.create({
  baseURL: commondef.cloudParkingUrl,
  headers: { 'Content-Type': 'application/json' },
  timeout: 50000
})

axioInstance.interceptors.response.use(handleRes, function (error) {
  return Promise.reject(error)
})

axioInstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const axioInstance2 = axios.create({
  baseURL: commondef.cloudParkingUrl,
  headers: { 'Content-Type': 'application/json' },
  timeout: 50000
})

axioInstance2.interceptors.response.use(handleRes, function (error) {
  return Promise.reject(error)
})

axioInstance2.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

function handleRes (response) {
  if (response.status >= 200 && response.status < 300) {
    const res = response.data
    if (res && res.code === commondef.resCode.Unauthorized) {
      // location.hash = '/login'

      return
    } else if (response.config.url.indexOf('/login') > 0) {
      accessToken = response.data.access_token
      // if (accessToken && process.browser) {
      localStorage.setItem(commondef.keyAccessToken, accessToken)
      // }
    }
    return res
  } else {
    return { status: response.status, code: Number.MAX_VALUE, msg: '' }
  }
}

export const axioForm = axios.create({
  headers: { 'Content-Type': 'multipart/form-data' },
  baseURL: commondef.cloudParkingUrl
})

axioForm.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axioForm.interceptors.response.use(handleRes, function (error) {
  return Promise.reject(error)
})

export const axioForm2 = axios.create({
  headers: { 'Content-Type': 'multipart/form-data' },
  baseURL: commondef.cloudParkingUrl
})

axioForm2.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axioForm2.interceptors.response.use(handleRes, function (error) {
  return Promise.reject(error)
})

// export const getPicUrl = (path) => {
//   return commondef.baseUrl + '/get_img/' + path
// }

export const axiosRequest = (url, para) => {
  // url  请求api
  // formData  form-date 请求
  // isNotToken  为true不要token
  const isNotToken = para && para.isNotToken
  const params = getPublicPara(isNotToken)
  if (para) {
    for (const item in para) {
      // eslint-disable-next-line no-prototype-builtins
      if (!para.hasOwnProperty(item)) {
        continue
      }
      if (item === 'formData' || item === 'isNotToken') {
        continue
      }
      if (para[item] || para[item] === 0 || para[item] === false) {
        params[item] = para[item]
      }
    }
  }
  if (para && para.formData) {
    const obj = new FormData()

    // 传参设置接口需要的json和file
    obj.append('json', JSON.stringify(params.json))
    if (params.files) {
      obj.append('files', params.files)
    } else if (params.file) {
      obj.append('file', params.file)
    }

    if (url.startWith('peripheral.') || url.startWith('video.')) {
      return axioForm2.post(url, obj)
    } else {
      return axioForm.post(url, obj)
    }
  } else {
    if (url.startWith('peripheral.') || url.startWith('video.')) {
      return axioInstance2.post(url, params)
    } else {
      return axioInstance.post(url, params)
    }
  }
}
// get请求
export const axiosRequestGet = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(url, { params }).then(res => {
      resolve(res.data)
    }).catch((err) => {
      reject(err.data)
    })
  })
}

export const urlList = {
  // 车主
  loginUrl: {
    // 登录
    login: '/login',
    // 退出登录
    logout: '/platform.user.logout'
  },
  setting: {
    // 修改密码
    updatePassword: '/platform.user.reset_password'

  },
  coupon: {
    // 车主查询优惠券
    queryCoupon: '/enterprise.coupon.list_by_vh'

  },
  parkRecord: {
    // 查询停车记录
    park: 'enterprise.vh_fee_order.get_parking_record'
  },
  payRecord: {
    // 查询停车缴费记录
    parkPay: '/enterprise.vh_fee_order.get_list'
  },
  month: {
    // 月租-办理长租
    longPay: '/enterprise.vh.add'
  },
  bill: {
    // 开具发票
    issueBill: '/pub/pay.bill.apply_direct',
    // 获取发票
    getBill: '/pub/pay.bill.park_plate.park_server.get_park_vh_pay_order'
  },
  // 支付
  queryPrice: 'third.fenglin.price.queryprice',
  // query_wx_appid: 'pay.wx.query_appid',
  query_wx_appid: 'pay.wx.get_appid',
  query_zfb_appid: 'pay.zfb.get_appid',
  query_customer: 'system.customer.query_by_sid',
  query_plate_number_order: '/s2p/pay.plate_number_order',
  vh_inout_pnull_in: 'third.fenglin.open_gate.vh_inout_pnull_in',
  get_vh_in_wait_info_by_channel_id: 'third.fenglin.vh_in_visitor.get_vh_in_wait_info_by_channel_id',
  add_visitor_info_with_in_uid: 'third.fenglin.vh_in_visitor.add_visitor_info_with_in_uid',
  vh_inout_out_open_gate: 'third.fenglin.open_gate.vh_inout_out_open_gate',
  get_openid_url: 'pay.get_openid_url',
  query_params_cache: 'pay.query_params_cache',
  get_pnumber_by_out_channel_id: 'third.fenglin.channel.get_pnumber_by_out_channel_id',
  query_trade_status: 'pay.trade_query',
  query_in_out_detail: 'third.fenglin.query.query_inout_detail',
  query_inout_detail: 'enterprise.vh_inout.query_inout_detail',
  car_owner: {
    bill: {
      // 获取账单列表（主要用来查询停车缴费）
      get_bill: 'car_owner.bill.get_bill'
    },
    vh: {
      // 获取可包月车类别列表 暂时没用
      get_vh_cate_and_rent: 'car_owner.vh.get_vh_cate_and_rent',
      // 获取可包月车类别列表 新
      get_monthly_card: 'car_owner.vh.get_monthly_card',
      // 获取绑定的长租车列表
      get_bind_rent_vh: 'car_owner.vh.get_bind_rent_vh',
      // 车牌或分组名充值支付前信息获取(主要用于延期/续租)
      get_vh_charge_info: 'car_owner.vh.get_vh_charge_info',
      // 修改已绑定的固定车车牌号
      modify_bind_rent_vh: 'car_owner.vh.modify_bind_rent_vh',
      // 月卡续费的账单查询
      get_rent_history: 'car_owner.vh.get_rent_history',
      // 获取文件上传参数
      get_driving_license_upload_url: 'car_owner.vh.get_driving_license_upload_url'

    }
  },
  pay: {
    get_sid_pay_way: 'pay.get_sid_pay_way',
    wx: {
      // 微信购买支付 前缀uuid
      do_h5_park_buy: 'pay.wx.do_h5_park_buy'
    },
    zfb: {
      // 微信购买支付 前缀uuid
      do_h5_park_buy: 'pay.zfb.do_h5_park_buy'
    },
    bill: {
      park_plate: {
        bind_phone_number: 'pay.bill.park_plate.bind_phone_number',
        get_self_info: 'pay.bill.park_plate.get_self_info'
      }
    }
  },
  advanced_setting: {
    vh_pay_recharge: {
      // 查询iot 车辆线上自助包月 配置 ：联系电话、姓名等是否展示
      query: 'advanced_setting.vh_pay_recharge.query'
    }
  }

}

export const filterMenu = function (rt, menu) {
  for (let i = 0; i < menu.length; i++) {
    filterPath(rt[0], menu[i].tag)
  }
  function filterPath (obj, path) {
    if (Array.isArray(obj.children) && obj.children.length) {
      for (let i = 0; i < obj.children.length; i++) {
        if (obj.children[i].path === path) {
          obj.children[i].allow = true
          return
        } else if (
          Array.isArray(obj.children[i].children) &&
                    obj.children[i].children.length
        ) {
          filterPath(obj.children[i], path)
        }
      }
    }
  }
  function delNotAllow (obj) {
    if (Array.isArray(obj.children) && obj.children.length) {
      for (let i = 0; i < obj.children.length; i++) {
        if (!obj.children[i].allow) {
          obj.children.splice(i, 1)
          return true
        } else if (
          Array.isArray(obj.children[i].children) &&
                    obj.children[i].children.length
        ) {
          const result = delNotAllow(obj.children[i])
          if (result) return true
        }
      }
    }
    return false
  }
  while (delNotAllow(rt[0])) { }

  return rt
}
