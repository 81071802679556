<template>
  <div>
    <van-popup v-model="localShow" @close="onTypeClose" position="bottom">
      <van-picker
        :columns="couponTypeColumns"
        @change="onTypeChange"
        @confirm="onTypeConfirm"
        @cancel="onTypeCancel"
        show-toolbar
        title="选择优惠券类型"
        visible-item-count="5"
        default-index="0"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  props: ['showCouponType'],
  data () {
    return {
      localShow: this.showTypePopup,
      couponTypeColumns: ['全部', '金额减免券', '时长减免券', '折扣减免券', '全免券']

    }
  },
  mounted () {

  },
  computed: {

  },
  watch: {
    showCouponType (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.localShow = newVal
    }
  },
  methods: {

    onTypeCancel () {
      this.$emit('getCancel')
    },
    onTypeConfirm (value) {
      // this.selTypeVal = value
      // this.localShow = false
      this.$emit('getConfirm', value)
    },
    onTypeChange () {

    },
    onTypeClose () {

    }

  }
}
</script>
<style scoped>
</style>
